<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="po-table-container">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3" style="margin-bottom: 20px">
              <label>Vendor</label>
              <select-field :options="vendorOptions" :label-text="'Vendors'" :option-display-keys="['name']" :option-val="'value'" v-model="vendorFilter" :allow-clear="true" />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" style="margin-top: 28px">
              <button v-if="!isLocked" class="btn" @click="openPO()">New PO</button>
            </div>
          </div>

          <h5>Purchase Orders</h5>
          <bh-table :table-settings="tableSettings" @colClick="openPO" />
        </div>
      </div>
    </div>
    <waiting-spinner :wait="wait" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import appFuncs from 'appFuncs'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'

import dateFormat from 'dateformat'
import SelectField from 'components/UIComponents/SelectField'

export default {
  name: 'JobPOs',
  data() {
    return {
      wait: {
        message: ''
      },

      vendorFilter: '',
      vendorOptions: [],
      allPOs: []
    }
  },

  components: {
    BhTable,
    SelectField,
    WaitingSpinner
  },

  props: {
    itmCode: {
      type: [String, Number],
      required: true
    },
    job: {
      type: Object,
      default: () => {}
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['vendors', 'employees', 'urls']),

    tableSettings() {
      return {
        tableData: this.filteredPOs,
        fields: {
          niceDate: {
            name: 'Date'
          },
          fullPONumber: {
            name: 'PO Number'
          },
          Vendor_Name: {
            name: 'Vendor'
          },
          Ordered_By_Name: {
            name: 'Ordered By'
          },
          Confirmed_By_Name: {
            name: 'Confirmed By'
          },
          Status_Flag: {
            name: 'Status'
          },
          PO_Total_Amount: {
            name: 'Total Amount'
          }
        },
        hideSearch: true
      }
    },

    filteredPOs() {
      if (this.vendorFilter) {
        return this.allPOs.filter(po => po.Vendor_Code == this.vendorFilter)
      }
      return this.allPOs
    }
  },

  methods: {
    getPOs() {
      const params = {
        action: 'get_purchase_orders',
        type: 'job',
        itmCode: this.itmCode,
        status: 'all',
        daysOld: 500
      }
      this.wait.message = 'Getting Purchase Orders'
      appFuncs
        .shRequest(params, 1)
        .then((data, res) => {
          if (Array.isArray(data)) {
            for (var i = data.length - 1; i >= 0; i--) {
              data[i].id = data[i].PO_Number
              data[i].niceDate = dateFormat(Date.parse(data[i]['PO_Date_List1']), 'mmm dS, yyyy')
              data[i].Vendor_Name = this.getVendorName(data[i].Vendor_Code) || data[i].Vendor_Code || '(empty)'

              const orderedBy = this.employees.find(itm => {
                return data[i].Ordered_By == itm.Employee_Code
              })

              data[i].Ordered_By_Name = orderedBy ? orderedBy.Employee_Name : data[i].Ordered_By

              const confirmedBy = this.employees.find(itm => {
                return data[i].Confirmed_By == itm.Employee_Code
              })
              data[i].Confirmed_By_Name = confirmedBy ? confirmedBy.Employee_Name : data[i].Confirmed_By

              const phaseMatches = data[i].Comment ? data[i].Comment.match(/^\[\s*(\d+)\s*\]/) : ''
              const phase = phaseMatches ? `-${phaseMatches[1]}` : ''
              data[i].Phase = phase
              data[i].fullPONumber = `${data[i].PO_Number}-${data[i].Job_Number}${phase}`

              const existingVendor = this.vendorOptions.find(vendor => vendor.value === data[i].Vendor_Code)
              if (!existingVendor) {
                this.vendorOptions.push({ name: data[i].Vendor_Name, value: data[i].Vendor_Code })
              }
            }
            this.allPOs = data
          } else {
            this.$snack.open('Problem getting purchase orders', 'error')
          }
        })
        .catch(res => {
          console.log(res)
          this.$snack.open('Problem getting purchase orders.', 'error')
        })
        .finally(() => {
          this.wait.message = ''
        })
    },

    getVendorName(code) {
      const vendor = this.vendors.find(itm => {
        return itm.Vendor_Code == code
      })
      if (vendor) {
        return vendor.Vendor_Name
      }
      return ''
    },

    openPO(obj) {
      const poNumber = ((obj || {}).itm || {}).PO_Number || ''
      this.$Modal({
        parent: this,
        name: 'post-po', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '', //!poNumber ? 'New PO' : 'Edit PO',
        component: () => import('components/Dashboard/PurchaseOrders/OpenPO.vue'),
        props: {
          type: 'Job',
          jobNumber: this.itmCode,
          job: this.job,
          poNumber
        }
      })
    }
  },

  mounted() {
    const employees = this.$store.dispatch('getEmployees')
    const vendors = this.$store.dispatch('getVendors')

    this.$bus.$emit('setWaiting', { name: 'getData', message: 'Getting Data' })
    Promise.all([employees, vendors])
      .then(() => {
        this.getPOs()
      })
      .finally(() => {
        this.$bus.$emit('stopWaiting', 'getData')
      })

    this.$bus.$on('added-po', this.getPOs)
  },

  beforeDestroy() {
    this.$bus.$off('added-po', this.getPOs)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';
</style>
