
<div>
  <div class="waiting-container" style="">
    <div class="row">
      <div :class="['col-sm-12', 'col-md-6', { 'col-lg-4': woid }]">
        <div v-if="woid" class="detail-item Xvisible-lg">
          <label @click="openAddWOStep2(woid)">WO Number</label>
          <div class="wo-number-wrapper is-link" @click="selectWO">
            <h4>{{ wo.WO_Number }}</h4>
            <i class="ti-angle-down"></i>
          </div>
        </div>

        <div :class="'detail-item form-group'">
          <label>Work Summary</label><span v-if="isEditable" class="required-star">*</span>
          <textarea v-if="isEditable" type="text" class="form-control" :value="decode(wo.Summary_Description)" @input="wo.Summary_Description = encode($event.target.value, 'stripTags')" rows="5" />
          <p v-else class="font-weight-bold">
            {{ wo.Summary_Description }}
          </p>
        </div>

        <div v-if="this.woid" :class="'detail-item form-group'">
          <button :class="['btn', 'btn-sm', { 'btn-primary': missingWoRequirements.length }]" @click="openWOAdminOptions" v-html="'Requirements'" />
          <button
            :class="[
              'btn',
              'btn-sm',
              {
                'btn-primary': !checkMetWoRequirement(['preWorkPhotos', 'postWorkPhotos'])
              }
            ]"
            @click="showFiles"
            v-html="'Add Photos'"
          />
          <button class="btn btn-sm btn-orange" @click="openCustomerWarnings" v-html="'Warnings'" v-if="show_warnings" />
        </div>

        <div :class="'detail-item form-group'">
          <div v-if="canEdit('Bill_Customer_Code')">
            <label>Customer</label><span class="required-star">*</span>
            <select-field-dynamic
              v-if="canEdit('Bill_Customer_Code')"
              :options="customers"
              :option-display-keys="['Name']"
              :option-val="'Customer_Code'"
              v-model="wo.Bill_Customer_Code"
              :empty-option-text="'No Customers Found'"
              :filter-on-key-val="{ key: 'Status', val: 'Active' }"
              label="Customer"
            />
          </div>
          <!-- <customer-select-field v-model="wo.Bill_Customer_Code" v-if="canEdit('Bill_Customer_Code')" /> -->
          <div v-else :class="'font-weight-bold'">
            <label>Customer</label>
            <p v-if="selectedCustomer">
              {{ selectedCustomer.Name }}
            </p>
          </div>
        </div>

        <div :class="'detail-item form-group'">
          <label>Site</label><span v-if="canEdit('WO_Job_Number')" class="required-star">*</span>
          <select-field-dynamic
            v-if="canEdit('WO_Job_Number')"
            :options="sites"
            :option-display-keys="['Name', 'site_address']"
            :option-val="'Ship_To_ID'"
            v-model="wo.WO_Job_Number"
            :empty-option-text="'No sites found, WO cannot be posted for this customer here'"
            :filter-on-key-val="{ key: 'Status', val: 'A' }"
            label="Site"
            :allow-add-item="true"
            @addItem="showAddSite"
          />
          <p v-else-if="selectedSite && selectedSite.Ship_To_Name" v-html="selectedSite.Ship_To_Name" :class="'font-weight-bold'" />
        </div>

        <div :class="'detail-item'" v-if="selectedSite && selectedSite.site_address">
          <label>Site Address</label>
          <p :class="'font-weight-bold'">
            <a target="_blank" :href="'https://maps.google.com/?q=' + selectedSite.site_address + ' ' + selectedSite.site_city"> {{ selectedSite.site_address }}, {{ selectedSite.site_city }} </a>
          </p>
        </div>
      </div>
      <div :class="['col-sm-12', 'col-md-6', { 'col-lg-4': woid }]">
        <div :class="'detail-item form-group'">
          <label>Customer PO Number</label>
          <br />
          <button v-if="woid" class="btn btn-sm" @click="requestPO">Request Via Email</button>
          <br />
          <input :disabled="!canEdit('Customer_PO_Number')" type="text" v-model="wo.Customer_PO_Number" :class="['form-control', { invalid: !wo.Customer_PO_Number }]" />
          <file-upload label="" :path="'wo-uploads/' + woid + '/'" :subdir="'po'" :zip-depth="-1" name="PO" :simple-upload="true" :upload-data="uploadData" />
        </div>

        <div :class="'detail-item form-group'">
          <!-- <customer-contact-select-field
            :customer-code="wo.Bill_Customer_Code"
            :label="'Requested By'"
            :default="wo.Bill_Contract"
            v-model="wo.Bill_Contract"
            v-if="canEdit('Bill_Contract')"
            :required="true"
            :output-name="true"
            @customerContactSelect="setRequestedBy"
          /> -->

          <label v-if="canEdit('Bill_Contract')">Requested By<span class="required-star">*</span></label>
          <select-field-dynamic
            v-show="canEdit('Bill_Contract') || allowRequestedByChange"
            :options="customerContactsFiltered"
            :option-display-keys="['Name']"
            :option-val="'Name'"
            v-model="wo.Bill_Contract"
            :empty-option-text="'Cannot fetch customer contacts'"
            :filter-on-key-val="{ key: 'Status', val: 'Active' }"
            label="Requested By"
            @selectItem="setRequestedBy"
            style="margin-bottom: 10px"
          />
          <div v-if="!canEdit('Bill_Contract') && !allowRequestedByChange">
            <label>Requested By</label>
            <input type="text" class="fake-readonly form-control" :value="wo.Bill_Contract" readonly @click="requestedByPreChange()" />
          </div>
          <div v-if="requestedBy && requestedBy.Name">
            <p>
              <a class="is-link" @click.prevent="editContact"
                ><strong>{{ requestedByStr }}</strong></a
              >
            </p>
          </div>
        </div>

        <div :class="'detail-item form-group'" v-if="selectedCustomer && selectedCustomer.direct_phone">
          <label>Requested By Phone</label>
          <p>
            <a :href="'tel:' + selectedCustomer.direct_phone" v-html="selectedCustomer.direct_phone" />
          </p>
        </div>

        <div class="detail-item form-group">
          <label>Region</label><span v-if="!isEditable" class="required-star">*</span>
          <select-field-dynamic :options="costCenterRegions" :option-display-keys="['name']" :option-val="'value'" :disabled="!isEditable" v-model="wo.region" />
        </div>

        <div :class="'detail-item form-group'" v-if="wo.region">
          <label>WO Division</label><span v-if="isEditable" class="required-star">*</span>
          <select-field-dynamic
            :options="woCostCenters"
            :option-display-keys="['description']"
            :option-val="'code'"
            v-model="wo.WO_Job_Division"
            :disabled="!canEdit('WO_Job_Division')"
            label="WO Division"
          />
        </div>

        <div :class="'detail-item form-group'" v-else>
          <label>WO Division</label><span v-if="isEditable" class="required-star">*</span>
          <select-field-dynamic
            :options="woJobDivisions"
            :option-display-keys="['code', 'description']"
            :option-val="'code'"
            v-model="wo.WO_Job_Division"
            :disabled="!canEdit('WO_Job_Division')"
            label="WO Division"
          />
        </div>

        <!-- <div :class="'detail-item form-group'">
          <label>Stage</label><span v-if="isEditable" class="required-star">*</span>
          <select-field-dynamic
            :options="woPriorityDescriptions"
            :option-display-keys="['description']"
            :option-val="'code'"
            v-model="wo.Priority_Code"
            :disabled="!canEdit('Priority_Code')"
            label="Stage"
          />
        </div> -->

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Project Lead</label><span v-if="isEditable" class="required-star">*</span>
          <select-field-dynamic
            :options="projectManagers"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.PM"
            :disabled="!woid || !isEditable"
            label="Project Lead"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Sales Person</label><span v-if="isEditable" class="required-star">*</span>
          <select-field-dynamic
            :options="projectManagers"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.Sales_Person"
            :disabled="!woid || !isEditable"
            label="Sales Person"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>
      </div>

      <div v-if="woid" class="col-sm-12 col-md-6 col-lg-4">
        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Lead Tech</label>
          <select-field-dynamic
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.tech_main"
            :disabled="!woid || !isEditable"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            label="Lead Tech"
          />
        </div>

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Created By</label>
          <select-field-dynamic
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.added_by"
            :disabled="true"
            label="Created By"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
          />
        </div>

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Techs</label>
          <select-field-multi
            :options="employees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.techs"
            :disabled="!woid || !isEditable"
            :placeholder-text="'Add Tech'"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            :label="'Techs'"
          />
        </div>

        <div :class="['detail-item', 'form-group']">
          <label>Ordered Date</label><span v-if="isEditable" class="required-star">*</span>
          <date-picker v-if="isEditable" v-model="wo.Ordered_Date" :disabled="!isEditable" :pre-filled="true" />
          <p v-else v-html="formatDate(wo.Ordered_Date, 1)" />
        </div>

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label v-if="wo.project_stage === 'not-started'">Expected Start Date</label>
          <label v-else>Start Date</label>
          <span v-if="isEditable" class="required-star">*</span>
          <input v-if="wo.id && wo.Requested_Date" :value="formatDate(wo.Requested_Date)" class="form-control fake-readonly fake-disabled" readonly @click="editRequiredDate" :disabled="!editable" />
          <date-picker v-else :disabled="!isEditable" v-model="wo.Requested_Date" :pre-filled="false" />
        </div>

        <div v-if="woid" :class="['detail-item', 'form-group']">
          <label>Expected Finished Date</label><span v-if="isEditable" class="required-star">*</span>
          <input
            v-if="wo.id && wo.Scheduled_Start_Date"
            :value="formatDate(wo.Scheduled_Start_Date)"
            class="form-control fake-readonly fake-disabled"
            readonly
            @click="editRequiredDate"
            :disabled="!editable"
          />
          <date-picker v-else :disabled="!isEditable" v-model="wo.Scheduled_Start_Date" :pre-filled="false" />
        </div>

        <div class="detail-item form-group">
          <label>Billing Agent</label>
          <select-field
            :options="billingEmployees"
            :option-display-keys="['Employee_Name']"
            :option-val="'Employee_Code'"
            v-model="wo.billing_employee"
            :disabled="!editable"
            :placeholder-text="'Select'"
            :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            :label="'Techs'"
          />
        </div>
      </div>
    </div>

    <br />

    <button :class="['btn', { pulse: hasUnsaved }, { 'btn-primary': hasUnsaved }]" @click="saveWO" v-html="saveText" v-if="!isLocked || allowRequestedByChange" />
    <waiting-spinner />
  </div>
</div>
