<template>
  <div>
    <div class="waiting-container" style="">
      <div class="row">
        <div :class="['col-sm-12', 'col-md-6', { 'col-lg-4': woid }]">
          <div v-if="woid" class="detail-item Xvisible-lg">
            <label @click="openAddWOStep2(woid)">WO Number</label>
            <div class="wo-number-wrapper is-link" @click="selectWO">
              <h4>{{ wo.WO_Number }}</h4>
              <i class="ti-angle-down"></i>
            </div>
          </div>

          <div :class="'detail-item form-group'">
            <label>Work Summary</label><span v-if="isEditable" class="required-star">*</span>
            <textarea v-if="isEditable" type="text" class="form-control" :value="decode(wo.Summary_Description)" @input="wo.Summary_Description = encode($event.target.value, 'stripTags')" rows="5" />
            <p v-else class="font-weight-bold">
              {{ wo.Summary_Description }}
            </p>
          </div>

          <div v-if="this.woid" :class="'detail-item form-group'">
            <button :class="['btn', 'btn-sm', { 'btn-primary': missingWoRequirements.length }]" @click="openWOAdminOptions" v-html="'Requirements'" />
            <button
              :class="[
                'btn',
                'btn-sm',
                {
                  'btn-primary': !checkMetWoRequirement(['preWorkPhotos', 'postWorkPhotos'])
                }
              ]"
              @click="showFiles"
              v-html="'Add Photos'"
            />
            <button class="btn btn-sm btn-orange" @click="openCustomerWarnings" v-html="'Warnings'" v-if="show_warnings" />
          </div>

          <div :class="'detail-item form-group'">
            <div v-if="canEdit('Bill_Customer_Code')">
              <label>Customer</label><span class="required-star">*</span>
              <select-field-dynamic
                v-if="canEdit('Bill_Customer_Code')"
                :options="customers"
                :option-display-keys="['Name']"
                :option-val="'Customer_Code'"
                v-model="wo.Bill_Customer_Code"
                :empty-option-text="'No Customers Found'"
                :filter-on-key-val="{ key: 'Status', val: 'Active' }"
                label="Customer"
              />
            </div>
            <!-- <customer-select-field v-model="wo.Bill_Customer_Code" v-if="canEdit('Bill_Customer_Code')" /> -->
            <div v-else :class="'font-weight-bold'">
              <label>Customer</label>
              <p v-if="selectedCustomer">
                {{ selectedCustomer.Name }}
              </p>
            </div>
          </div>

          <div :class="'detail-item form-group'">
            <label>Site</label><span v-if="canEdit('WO_Job_Number')" class="required-star">*</span>
            <select-field-dynamic
              v-if="canEdit('WO_Job_Number')"
              :options="sites"
              :option-display-keys="['Name', 'site_address']"
              :option-val="'Ship_To_ID'"
              v-model="wo.WO_Job_Number"
              :empty-option-text="'No sites found, WO cannot be posted for this customer here'"
              :filter-on-key-val="{ key: 'Status', val: 'A' }"
              label="Site"
              :allow-add-item="true"
              @addItem="showAddSite"
            />
            <p v-else-if="selectedSite && selectedSite.Ship_To_Name" v-html="selectedSite.Ship_To_Name" :class="'font-weight-bold'" />
          </div>

          <div :class="'detail-item'" v-if="selectedSite && selectedSite.site_address">
            <label>Site Address</label>
            <p :class="'font-weight-bold'">
              <a target="_blank" :href="'https://maps.google.com/?q=' + selectedSite.site_address + ' ' + selectedSite.site_city"> {{ selectedSite.site_address }}, {{ selectedSite.site_city }} </a>
            </p>
          </div>
        </div>
        <div :class="['col-sm-12', 'col-md-6', { 'col-lg-4': woid }]">
          <div :class="'detail-item form-group'">
            <label>Customer PO Number</label>
            <br />
            <button v-if="woid" class="btn btn-sm" @click="requestPO">Request Via Email</button>
            <br />
            <input :disabled="!canEdit('Customer_PO_Number')" type="text" v-model="wo.Customer_PO_Number" :class="['form-control', { invalid: !wo.Customer_PO_Number }]" />
            <file-upload label="" :path="'wo-uploads/' + woid + '/'" :subdir="'po'" :zip-depth="-1" name="PO" :simple-upload="true" :upload-data="uploadData" />
          </div>

          <div :class="'detail-item form-group'">
            <!-- <customer-contact-select-field
              :customer-code="wo.Bill_Customer_Code"
              :label="'Requested By'"
              :default="wo.Bill_Contract"
              v-model="wo.Bill_Contract"
              v-if="canEdit('Bill_Contract')"
              :required="true"
              :output-name="true"
              @customerContactSelect="setRequestedBy"
            /> -->

            <label v-if="canEdit('Bill_Contract')">Requested By<span class="required-star">*</span></label>
            <select-field-dynamic
              v-show="canEdit('Bill_Contract') || allowRequestedByChange"
              :options="customerContactsFiltered"
              :option-display-keys="['Name']"
              :option-val="'Name'"
              v-model="wo.Bill_Contract"
              :empty-option-text="'Cannot fetch customer contacts'"
              :filter-on-key-val="{ key: 'Status', val: 'Active' }"
              label="Requested By"
              @selectItem="setRequestedBy"
              style="margin-bottom: 10px"
            />
            <div v-if="!canEdit('Bill_Contract') && !allowRequestedByChange">
              <label>Requested By</label>
              <input type="text" class="fake-readonly form-control" :value="wo.Bill_Contract" readonly @click="requestedByPreChange()" />
            </div>
            <div v-if="requestedBy && requestedBy.Name">
              <p>
                <a class="is-link" @click.prevent="editContact"
                  ><strong>{{ requestedByStr }}</strong></a
                >
              </p>
            </div>
          </div>

          <div :class="'detail-item form-group'" v-if="selectedCustomer && selectedCustomer.direct_phone">
            <label>Requested By Phone</label>
            <p>
              <a :href="'tel:' + selectedCustomer.direct_phone" v-html="selectedCustomer.direct_phone" />
            </p>
          </div>

          <div class="detail-item form-group">
            <label>Region</label><span v-if="!isEditable" class="required-star">*</span>
            <select-field-dynamic :options="costCenterRegions" :option-display-keys="['name']" :option-val="'value'" :disabled="!isEditable" v-model="wo.region" />
          </div>

          <div :class="'detail-item form-group'" v-if="wo.region">
            <label>WO Division</label><span v-if="isEditable" class="required-star">*</span>
            <select-field-dynamic
              :options="woCostCenters"
              :option-display-keys="['description']"
              :option-val="'code'"
              v-model="wo.WO_Job_Division"
              :disabled="!canEdit('WO_Job_Division')"
              label="WO Division"
            />
          </div>

          <div :class="'detail-item form-group'" v-else>
            <label>WO Division</label><span v-if="isEditable" class="required-star">*</span>
            <select-field-dynamic
              :options="woJobDivisions"
              :option-display-keys="['code', 'description']"
              :option-val="'code'"
              v-model="wo.WO_Job_Division"
              :disabled="!canEdit('WO_Job_Division')"
              label="WO Division"
            />
          </div>

          <!-- <div :class="'detail-item form-group'">
            <label>Stage</label><span v-if="isEditable" class="required-star">*</span>
            <select-field-dynamic
              :options="woPriorityDescriptions"
              :option-display-keys="['description']"
              :option-val="'code'"
              v-model="wo.Priority_Code"
              :disabled="!canEdit('Priority_Code')"
              label="Stage"
            />
          </div> -->

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Project Lead</label><span v-if="isEditable" class="required-star">*</span>
            <select-field-dynamic
              :options="projectManagers"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.PM"
              :disabled="!woid || !isEditable"
              label="Project Lead"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            />
          </div>

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Sales Person</label><span v-if="isEditable" class="required-star">*</span>
            <select-field-dynamic
              :options="projectManagers"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.Sales_Person"
              :disabled="!woid || !isEditable"
              label="Sales Person"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            />
          </div>
        </div>

        <div v-if="woid" class="col-sm-12 col-md-6 col-lg-4">
          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Lead Tech</label>
            <select-field-dynamic
              :options="employees"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.tech_main"
              :disabled="!woid || !isEditable"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
              label="Lead Tech"
            />
          </div>

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Created By</label>
            <select-field-dynamic
              :options="employees"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.added_by"
              :disabled="true"
              label="Created By"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
            />
          </div>

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Techs</label>
            <select-field-multi
              :options="employees"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.techs"
              :disabled="!woid || !isEditable"
              :placeholder-text="'Add Tech'"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
              :label="'Techs'"
            />
          </div>

          <div :class="['detail-item', 'form-group']">
            <label>Ordered Date</label><span v-if="isEditable" class="required-star">*</span>
            <date-picker v-if="isEditable" v-model="wo.Ordered_Date" :disabled="!isEditable" :pre-filled="true" />
            <p v-else v-html="formatDate(wo.Ordered_Date, 1)" />
          </div>

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label v-if="wo.project_stage === 'not-started'">Expected Start Date</label>
            <label v-else>Start Date</label>
            <span v-if="isEditable" class="required-star">*</span>
            <input v-if="wo.id && wo.Requested_Date" :value="formatDate(wo.Requested_Date)" class="form-control fake-readonly fake-disabled" readonly @click="editRequiredDate" :disabled="!editable" />
            <date-picker v-else :disabled="!isEditable" v-model="wo.Requested_Date" :pre-filled="false" />
          </div>

          <div v-if="woid" :class="['detail-item', 'form-group']">
            <label>Expected Finished Date</label><span v-if="isEditable" class="required-star">*</span>
            <input
              v-if="wo.id && wo.Scheduled_Start_Date"
              :value="formatDate(wo.Scheduled_Start_Date)"
              class="form-control fake-readonly fake-disabled"
              readonly
              @click="editRequiredDate"
              :disabled="!editable"
            />
            <date-picker v-else :disabled="!isEditable" v-model="wo.Scheduled_Start_Date" :pre-filled="false" />
          </div>

          <div class="detail-item form-group">
            <label>Billing Agent</label>
            <select-field
              :options="billingEmployees"
              :option-display-keys="['Employee_Name']"
              :option-val="'Employee_Code'"
              v-model="wo.billing_employee"
              :disabled="!editable"
              :placeholder-text="'Select'"
              :filter-on-key-val="{ key: 'Employment_Status', val: 'A' }"
              :label="'Techs'"
            />
          </div>
        </div>
      </div>

      <br />

      <button :class="['btn', { pulse: hasUnsaved }, { 'btn-primary': hasUnsaved }]" @click="saveWO" v-html="saveText" v-if="!isLocked || allowRequestedByChange" />
      <waiting-spinner />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import appFuncs from 'appFuncs'
import SelectField from 'components/UIComponents/SelectField'
import SelectFieldDynamic from 'components/UIComponents/SelectFieldDynamic'
import SelectFieldMulti from 'components/UIComponents/SelectFieldMulti'
import CustomerSelectField from 'components/UIComponents/CustomerSelectField'
import CustomerContactSelectField from 'components/UIComponents/CustomerContactSelectField'
import DatePicker from 'components/UIComponents/DatePicker'
import navTabs from 'mixins/navTabs'
import WOFuncs from 'mixins/WOFuncs'
import WoFiles from 'components/Dashboard/JobWo/WOFiles'
import WOAdminOptions from 'src/components/Dashboard/JobWo/WOAdminOptions.vue'
import FileUpload from 'components/UIComponents/FileUpload'
import { diff, detailedDiff } from 'deep-object-diff'
import ItemComments from 'components/GeneralViews/ItemCommentsWYSIWYG'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import store from 'store'
import { showConfirmDialog } from 'src/confirmDialog'
import { isAfter, parseISO, isSameDay } from 'date-fns'
import snack from '../../UIComponents/Snackbar'
import billingFuncs from 'mixins/billingFuncs'

export default {
  data() {
    return {
      newWo: false,
      wo: {
        Dispatch_Status_Code: '1', // tbs
        Priority_Code: '',
        Bill_Contract: '',
        Customer_PO_Number: '',
        WO_Job_Number: '',
        WO_Job_Division: '',
        Bill_Customer_Code: '',
        Summary_Description: '',
        tech_main: '',
        techs: [],
        region: '',
        Ordered_Date: ''
      },
      loadedDispatch_Status_Code: '',
      fieldsUpdateable: ['Bill_Contract', 'Customer_PO_Number', 'Summary_Description', 'Priority_Code', 'Dispatch_Status_Code', 'WO_Job_Division'],
      hasUnsaved: false,
      projectManagerExisting: '',
      salesPersonExisting: '',
      loadedData: {},
      requestedById: '',
      requestedBy: {},
      allowRequestedByChange: false,
      show_warnings: false,
      hasUnsavedChangesInterval: null
    }
  },

  mixins: [navTabs, WOFuncs, billingFuncs],

  props: {
    woid: {
      type: [Number, String],
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    },
    componentData: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  components: {
    SelectField,
    CustomerSelectField,
    CustomerContactSelectField,
    DatePicker,
    SelectFieldMulti,
    SelectFieldDynamic,
    FileUpload,
    WaitingSpinner
  },

  computed: {
    ...mapGetters(['woCostCenters', 'woJobDivisions', 'customers', 'woSites', 'customerContacts', 'woBaseDetails', 'userAuthedData', 'unsavedData', 'employees', 'configuredCostCenters']),

    addEdit() {
      let out = {}
      if (this.woId) {
        out.colClasses = 'col-sm-12 col-md-6 col-lg-4'
      } else {
        out.colClasses = 'col-sm-12 col-md-6'
      }
      return out
    },

    urls() {
      return this.$store.getters.urls
    },

    isAdmin() {
      return this.$store.getters.userAuthedData.user_role.indexOf('admin') !== -1
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    },

    isWOTech() {
      if (this.userID == this.wo.tech_main) {
        return true
      }
      if (this.wo.techs && this.wo.techs.length && this.wo.techs.indexOf(this.userID.toString()) !== -1) {
        return true
      }
      return false
    },

    userID() {
      return this.userAuthedData.eid
    },

    isEditable() {
      if (!this.woid || this.editable) {
        return true
      }
      return false
    },

    requestedByStr() {
      if (this.requestedBy && this.requestedBy.Name) {
        return `${this.requestedBy.Name} - ${this.requestedBy.Email}`
      }
      return ''
    },

    selectedCustomer() {
      let cust = this.customers.find(itm => {
        return itm.Customer_Code == this.wo.Bill_Customer_Code
      })
      if (cust) {
        this.$emit('selectedCustomer', cust)
      }
      return cust
    },

    sites() {
      if (this.wo && this.wo.Bill_Customer_Code && this.woSites.length) {
        const sites = this.woSites.filter(itm => {
          return itm.Ship_To_Customer_Code == this.wo.Bill_Customer_Code
        })
        return sites
      }
      return []
    },

    selectedSite() {
      if (this.wo && this.wo.WO_Job_Number) {
        const site = this.woSites.find(itm => {
          return itm.Ship_To_ID == this.wo.WO_Job_Number
        })
        if (site) {
          return site
        }
      }
      return {}
    },

    saveText() {
      if (this.woid) {
        return 'Update'
      }
      return 'Post New WO'
    },

    customerContactsFiltered() {
      return this.customerContacts.filter(itm => {
        return itm.Account_Customer_Code__c == this.wo.Bill_Customer_Code
      })
    },

    projectManagers() {
      let emps = this.employees.filter(itm => {
        if (Array.isArray(itm.roles)) {
          return itm.roles.indexOf('pm') !== -1
        }
      })
      return emps
    },

    uploadData() {
      return {
        WO_Number: this.woid,
        Image_Type: 'PDF',
        Image_Description: 'PO Image',
        Category: 'WO'
      }
    },

    costCenterRegions() {
      let costCenters = Array.isArray(this.configuredCostCenters) ? this.configuredCostCenters : null
      if (!costCenters) return []
      return costCenters.map(itm => {
        return {
          name: itm.name,
          value: itm.id
        }
      })
    }
  },

  methods: {
    selectWO() {
      this.$Modal({
        parent: this,
        name: 'job-wo-select', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Select WO',
        component: () => import('components/Dashboard/JobWo/JobWOSearch.vue'),
        props: {
          type: 'WO'
        }
      })
    },

    canEdit(fieldName) {
      if ((this.isEditable && this.fieldsUpdateable.indexOf(fieldName) !== -1) || !this.woid) {
        return true
      }
      return false
    },

    requestedByPreChange() {
      if (this.wo.Bill_Contract) {
        if (!confirm('You are changing the requested by. This is the customer name responsible for requesting the work.  Changing the name here will mean the customer name on invoices also changes'))
          return
      }
      this.allowRequestedByChange = true
    },

    setOwner() {
      // if sales lead is not set, set based on the heiarchy of account, site, contact owner from Salesforce
      if (this.salesPersonExisting) return

      let owner = null
      let type = null
      let requestedById = this.requestedBy ? this.requestedBy.Id : null
      const contact = this.customerContacts.find(itm => {
        return itm.Id == requestedById
      })
      owner = contact ? contact.RealOwnerEmployeeCode__c : null
      type = owner ? 'contact' : null

      if (!owner) {
        const site = this.woSites.find(itm => {
          return itm.Ship_To_ID == this.wo.WO_Job_Number
        })
        owner = site ? site.RealOwnerEmployeeCode__c : null
        type = owner ? 'site' : null
      }

      if (!owner) {
        const customer = this.customers.find(itm => {
          return itm.Customer_Code == this.wo.Bill_Customer_Code
        })
        owner = customer ? customer.RealOwnerEmployeeCode__c : null
        type = owner ? 'customer' : null
      }

      if (owner) {
        const validOwner = this.employees.find(itm => {
          return itm.Employee_Code == owner && itm.Employment_Status === 'A'
        })
        if (validOwner) {
          console.log('set salesPerson', owner)
          this.wo.Sales_Person = owner
        }
      }
    },

    editRequiredDate() {
      this.$Modal({
        parent: this,
        name: 'SetEstimatedStartDate',
        size: 'lg',
        title: 'Project Dates',
        component: () => import('components/Dashboard/JobWo/SetEstimatedStartDate.vue'),
        props: {
          obj: this.wo,
          type: 'WO'
        }
      })
    },

    async saveWO() {
      if (this.isLocked && !this.allowRequestedByChange) {
        console.log('cannot update locked wo')
        return
      }

      if (!this.woid) {
        this.wo.Dispatch_Status_Code = '1'
        this.wo.Priority_Code = '1'
      }

      if ((this.wo.Dispatch_Status_Code == '7' || this.wo.Dispatch_Status_Code == 'F') && this.hasMissingWoRequirements) {
        alert('All WO requirements must be met before WO can be marked with Finished Dispatch Status')
        return
      }

      const startDate = this.wo.Requested_Date ? parseISO(this.wo.Requested_Date) : null

      const finishDate = this.wo.Scheduled_Start_Date ? parseISO(this.wo.Scheduled_Start_Date) : null
      const finishDateValid = finishDate && (isAfter(finishDate, new Date()) || isSameDay(finishDate, new Date())) && (isAfter(finishDate, startDate) || isSameDay(finishDate, startDate))
      const validateInStage = ['not-started', 'on-going', 'stagnant'].includes(this.wo.project_stage)

      if (!finishDateValid && validateInStage) {
        return this.$snack.open('Finish Date must not be in the past', 'warning')
      }

      const data = {
        action: 'post_work_order',
        data: this.wo
      }

      if (this.woid && !data.data.WO_Number) {
        this.$snack.open('Problem with WO data, please reload to update.', 'error')
      }

      const doValidate = !this.woid || this.wo.Dispatch_Status_Code === 'F' || this.wo.Dispatch_Status_Code === '7'

      if (doValidate) {
        // check for required fields
        let required = [
          { key: 'Summary_Description', name: 'Work Summary' },
          { key: 'Bill_Customer_Code', name: 'Customer' },
          { key: 'WO_Job_Number', name: 'Site' },
          { key: 'Bill_Contract', name: 'Requested By' },
          { key: 'WO_Job_Division', name: 'WO Division' },
          { key: 'Priority_Code', name: 'Dispatch Status' },
          { key: 'Dispatch_Status_Code', name: 'Stage' }
        ]

        if (this.woid) {
          required.push({ key: 'Ordered_Date', name: 'Ordered Date' })
          required.push({ key: 'Requested_Date', name: 'Requested Date' })
          required.push({ key: 'tech_main', name: 'Lead Tech' })

          if (!this.wo.PM) {
            this.$snack.open('Project Lead required', 'warning')
            return
          }

          // if (!this.salesPerson) {
          //   this.$snack.open('Project Lead required', 'warning');
          //   return;
          // }
        } else {
          // region required if new WO
          required.push({ key: 'region', name: 'Region' })
          this.newWO = true
          // this.wo.Ordered_Date = this.formatDate(new Date())
        }

        let valid = true
        for (var i = required.length - 1; i >= 0; i--) {
          if (!this.wo[required[i].key]) {
            this.$snack.open(required[i].name + ' is required', 'warning')
            valid = false
            continue
          }
        }

        if (!valid) {
          return
        }
      }

      if (!this.wo.id && (await this.isOnHold(this.wo.Bill_Customer_Code))) return

      // validate dispatch status and finished date
      if (this.wo['Dispatch_Status_Code'] !== '7' && this.wo['Dispatch_Status_Code'] !== 'F') {
        this.wo.Complete_Date = ''
      }

      if (this.wo.region) {
        const matchingCostCenter = this.woCostCenters.find(center => center.code == this.wo.WO_Job_Division)

        if (matchingCostCenter) {
          data.data.Cost_Center = matchingCostCenter[this.wo.region]
        } else {
          this.$snack.open('No matching cost center found for the selected region.', 'error')
          return
        }
      } else {
        // If wo.region does not have a value, use the existing switch-case logic
        switch (data.data.WO_Job_Division) {
          case '1':
          case '2':
          case '8':
          case '9':
          case '6':
          case '10':
            data.data.Cost_Center = '2000'
            break
          case '11':
          case '3':
          case '5':
          case '17':
            data.data.Cost_Center = '6000'
            break
          case '12':
          case '4':
          case '13':
          case '14':
            data.data.Cost_Center = '5000'
            break
          case '16':
            data.data.Cost_Center = '7000'
            break
          default:
          // Handle the case where WO_Job_Division does not match any case
          // this.$snack.open('No matching cost center found for WO_Job_Division.', 'error');
          // return; // Optional: Stop execution if WO_Job_Division does not match any case
        }
      }

      // blank this to prevent update issues
      data.data.Material_Price_Level = ''
      data.data.Price_Level = ''

      data.data.overrideLocked = this.allowRequestedByChange

      // update mysdb wo data first if existing WO
      if (this.woid) {
        this.updateAppData()
      }

      this.$bus.$emit('setWaiting', {
        name: data.action,
        message: 'Posting Work Order'
      })

      /*
      - Posting new WO
        1. Post to Spectrum
        2. Fetch wo assembly in order to get and match new WO number
        3. Update mysdb data using WO Number
      */

      appFuncs.ajax_request(this.$store.getters.sherAuth, data, res => {
        this.$bus.$emit('stopWaiting', data.action)
        if (res.status === 'success') {
          this.$snack.open('Work Order successfully ' + (this.woid ? 'updated.' : 'added.'))
          this.$store.dispatch('unsavedDataClear')

          if (this.woid && (this.wo.PM != this.projectManagerExisting || this.wo.Sales_Person != this.salesPersonExisting)) {
            // console.log('save pm or sp', this.wo.PM, this.wo.Sales_Person)
            this.saveProjectManager().finally(() => {
              this.postWOUpdate(res)
            })
          } else {
            this.postWOUpdate(res)
          }
          this.loadedDispatch_Status_Code = this.wo.Dispatch_Status_Code
        } else {
          this.$snack.open(res.message || 'Problem updating Work Order data.', 'error')
        }
      })
    },

    setRequestedBy() {
      if (!this.wo.Bill_Contract) return

      const match = appFuncs.contactNameToAppId(this.wo.Bill_Contract, this.customerContacts, true)

      if (match) {
        this.requestedById = match.Id
        this.requestedBy = match
        this.wo.requested_by_id = match.Id
        this.setOwner()
      }
    },

    listenEditContact() {
      this.setRequestedBy()
    },

    async postWOUpdate(res) {
      // @TODO need to change this event hook to not do another data fetch - currently causes extra wo assemble fetch
      // this.$bus.$emit('addUpdateWO')

      if (res.newWONumber) {
        this.$bus.$emit('setWaiting', { name: 'postWOUpdate', message: 'Updating Work Order' })

        this.woid = res.newWONumber

        try {
          await this.updateAppData(true) // true for is new
        } catch (e) {
          console.log(e)
        }

        try {
          await this.saveProjectManager() // true for is new
        } catch (e) {
          console.log(e)
        }

        try {
          await this.loadData(true)
        } catch (e) {
          console.log(e)
        }

        this.$bus.$emit('stopWaiting', 'postWOUpdate')

        this.openAddWOStep2(this.woid)
        // this.$parent.openCustomerUpdatePrompt();
      }

      this.loadedData = JSON.parse(JSON.stringify(this.wo))

      // important to get WODetails to make changes inc keys to this component
      if (typeof this.$parent.loadWO === 'function') {
        this.$parent.loadWO()
      }

      this.$bus.$emit('modalClose', 'wo-add-edit')
    },

    openAddWOStep2(woid) {
      if (!woid) {
        return
      }

      this.$Modal({
        parent: this,
        name: 'wo-add-step-2', // used for closing specific modal programmatically
        size: 'lg', // sm, md, lg, xl
        hideClose: false,
        title: 'Add Work Order',
        component: () => import('src/components/Dashboard/JobWo/WOAddStep2.vue'),
        contentClasses: '',
        props: {
          woid
        }
      })
    },

    saveProjectManager() {
      return new Promise((resolve, reject) => {
        const data = {
          action: 'update_wo_pm',
          wo_number: this.woid,
          pm: this.wo.PM,
          sales_person: this.wo.Sales_Person
        }
        this.$bus.$emit('setWaiting', {
          name: 'updateProjectManager',
          message: 'Updating Job UDFs'
        })
        appFuncs
          .shRequest(data)
          .then(data => {
            resolve()
          })
          .catch(data => {
            console.log(data)
            const message = data.message && data.message.indexOf('invalid') !== -1 ? 'Cannot update using selected Project Lead' : data.message
            this.$snack.open(message, 'error')
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'updateProjectManager')
          })
      })
    },

    updateAppData(isNew) {
      return new Promise(async (resolve, reject) => {
        if (!this.woid) {
          resolve()
          return
        }

        const techs = this.wo.techs ? JSON.stringify(this.wo.techs) : ''

        const params = {
          action: 'upsert_wos_app_data',
          id: this.woid,
          data: {
            tech_main: this.wo.tech_main || null,
            techs,
            requested_by_id: this.requestedById,
            isNew
          }
        }

        if (isNew) {
          params.data.added_by = this.userAuthedData.eid
          params.data.added_by_name = `${this.userAuthedData.fname} ${this.userAuthedData.mname} ${this.userAuthedData.lname}`
        }

        try {
          this.$bus.$emit('setWaiting', { name: params.action, message: 'Updating App Data' })
          await appFuncs.shRequest(params)
          resolve()
        } catch (e) {
          this.$snack.open('Problem updating some data, please try again.', 'error')
          reject()
        }
        this.$bus.$emit('stopWaiting', params.action)
      })
    },

    setLoadedData() {
      this.loadedData = JSON.parse(JSON.stringify(this.wo))
    },

    hasUnsavedChanges() {
      const trackedItems = [
        'Bill_Contract',
        'Dispatch_Description',
        'Dispatch_Status_Code',
        'Lead_Name',
        'Ordered_Date',
        'PM',
        'Priority_Description',
        // 'Requested_Date',
        'Sales_Person',
        'Summary_Description',
        'WO_Reference_Code',
        'dispatch_satus_desc',
        'lead',
        'ordered_date_formatted',
        'tech_main',
        'techs',
        'Customer_PO_Number',
        'WO_Job_Division',
        'Priority_Code'
      ]
      const wo = JSON.parse(JSON.stringify(this.wo))
      const loadedItems = this.filterObjectByKeys(this.loadedData, trackedItems)
      let addedItems = this.filterObjectByKeys(wo, trackedItems)
      const unsaved = this.loosieDiff(loadedItems, addedItems)

      if (!this.isObjEmpty(unsaved)) {
        console.log('unsaved', unsaved)
        this.hasUnsaved = true
        this.$store.dispatch('unsavedDataSet')
        return true
      }
      this.hasUnsaved = false
      this.$store.dispatch('unsavedDataClear')

      return false
    },

    showFiles() {
      this.$Modal({
        parent: this,
        name: 'wo-files', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Add WO Photos',
        component: WoFiles,
        props: {
          woid: this.woid,
          showTabs: ['Pre-Work', 'Post-Work'],
          stayPilled: true,
          isOutlined: false
        }
      })
      // this.$bus.$emit('modalClose', 'wo-admin-options');
    },

    showAddSite() {
      this.$Modal({
        parent: this,
        name: 'AddWOSite', // used for closing specific modal programmatically
        title: 'Add Site',
        size: 'xl', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/JobWo/AddWOSite'),
        props: {
          customerCode: this.wo.Bill_Customer_Code
        }
      })
    },

    editContact(event) {
      this.$Modal({
        parent: this,
        name: 'customer-contact-add-edit', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        title: '',
        component: () => import('src/components/Dashboard/Contacts/CustomerContactsAddEdit.vue'),
        props: {
          editId: this.requestedById,
          preSelected: {
            Account_Customer_Code__c: this.wo.Bill_Customer_Code
          }
        }
      })
    },

    openWOAdminOptions() {
      this.$Modal({
        parent: this,
        name: 'wo-admin-options', // used for closing specific modal programmatically
        size: 'md', // sm, md, lg, xl
        hideClose: false,
        title: 'Work Order Options',
        component: WOAdminOptions,
        props: {
          woid: this.woid,
          tab: 'Main',
          woRequirementItems: this.woRequirementItems,
          woOptions: this.woOptions
        }
      })
    },

    openCustomerWarnings() {
      this.$Modal({
        parent: this,
        name: 'ShowCustomerWarnings', // used for closing specific modal programmatically
        size: 'sm', // sm, md, lg, xl
        hideClose: false,
        component: () => import('src/components/UIComponents/ShowCustomerWarnings.vue'),
        contentClasses: 'smoke-bg',
        props: {
          customerCode: this.wo.Bill_Customer_Code
        }
      })
    },

    requestPO() {
      this.$Modal({
        parent: this,
        name: 'PORequestEmail', // used for closing specific modal programmatically
        title: 'Request PO',
        size: 'md', // sm, md, lg, xl
        component: () => import('src/components/Dashboard/JobWo/PORequestEmail.vue'),
        props: {
          woNumber: this.woid || ''
        }
      })
    },

    listenRefreshWO() {
      this.loadData('refresh')
    },

    listenAddWOSite(obj) {
      if (obj && obj.Ship_To_ID) {
        this.wo.WO_Job_Number = obj.Ship_To_ID
      }
    },

    loadData(refresh) {
      return new Promise(async resolve => {
        if (!this.woid || (!refresh && this.wo.WO_Number)) {
          resolve()
          return
        }

        this.$bus.$emit('setWaiting', { name: 'getWO', message: 'Getting WO Data' })

        const params = {
          action: 'assemble_wip_wo_data',
          woNumber: this.woid
        }

        appFuncs
          .shRequest(params)
          .then(data => {
            const woNumber = data?.[0]?.WO_Number
            if (!woNumber) {
              this.$snack.open('Problem fetching WO', 'error')
              return
            }
            this.wo = data[0]
            this.salesPersonExisting = this.wo.Sales_Person
            this.projectManagerExisting = this.wo.PM
            this.loadedDispatch_Status_Code = this.wo.Dispatch_Status_Code

            this.setLoadedData()
          })
          .catch(err => {
            this.$snack.open('Problem refreshing WO data, please close and try again', 'error')
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'getWO')
          })
        resolve()
      })
    },

    listenSetStartFinishDates(startDate, finishDate) {
      this.wo.Requested_Date = startDate
      this.wo.Scheduled_Start_Date = finishDate
    },

    listenaddProjectUpdate(dateChangeLog) {
      this.wo.date_change_log = JSON.parse(dateChangeLog)
    },

    async isOnHold(code) {
      const selectedCustomer = this.customers.find(e => e.Customer_Code === code)
      if (selectedCustomer?.on_hold === 'yes' && !this.isAdmin) {
        if (!(await showConfirmDialog('Warning: this account is on-hold. Please ask your supervisor for support', true))) return true
        return true
      }
      return false
    }
  },

  async mounted() {
    this.$bus.$emit('setWaiting', {
      name: 'getData',
      message: 'Getting Data'
    })

    const employees = this.$store.dispatch('getEmployees')
    const sites = this.$store.dispatch('getWOSites')
    const customers = this.$store.dispatch('getCustomers')
    const customerContacts = this.$store.dispatch('getCustomerContacts')
    const woData = this.loadData()
    const configuredCostCenters = this.$store.dispatch('getConfiguredCostCenters')
    const woCostCenters = this.$store.dispatch('getWoCostCenters') // Dispatch the new action here

    await Promise.all([employees, sites, customers, customerContacts, woData, configuredCostCenters, woCostCenters]).catch(err => {
      console.log('error', err)
      this.$snack.open('Problem getting data, please try again.', 'error')
    })

    this.setRequestedBy()

    if (!this.woid) {
      const employee = this.employees.find(itm => itm.Employee_Code == this.userAuthedData.eid)
      console.log('employee', employee)
      this.wo.region = employee ? employee.default_region : ''
    }

    this.$bus.$emit('stopWaiting', 'getData')

    this.hasUnsavedChangesInterval = setInterval(() => {
      this.hasUnsavedChanges()
    }, 1000)

    this.getWoRequirementItems()

    let params = {
      action: 'get_item_notes',
      table: 'CustomerWarnings',
      item_id: this.wo.Bill_Customer_Code
    }

    this.show_warnings = false

    this.wait = { message: 'Getting notes' }
    appFuncs.ajax_request(store.getters.sherAuth, params, result => {
      this.wait = { message: '' }
      if (result.status == 'success') {
        let allNotes = result.data
        if (allNotes.length > 0) {
          this.show_warnings = true
        }
      } else {
        this.$snack.open(result.message || 'Problem getting notes', 'error')
      }
    })

    this.$bus.$on('refreshWODetailsBase', this.listenRefreshWO)
    this.$bus.$on('addedWOSite', this.listenAddWOSite)
    this.$bus.$on('contactEdit', this.listenEditContact)
    // this.$bus.$on('checkUnsavedChanges', this.hasUnsavedChanges)
    this.$bus.$on('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$on('addProjectUpdate', this.listenaddProjectUpdate)
  },

  watch: {
    async 'wo.Bill_Customer_Code'(newVal, oldVal) {
      if (oldVal && this.wo.WO_Job_Number) {
        this.wo.WO_Job_Number = ''
      }
      if (!this.wo.id && (await this.isOnHold(newVal))) {
        this.wo.Bill_Customer_Code = oldVal
      }
    },

    'wo.Summary_Description'(newVal, oldVal) {
      if (newVal && newVal.length > 240) {
        this.wo.Summary_Description = oldVal
        this.$snack.open('Maximum character limit reached.', 'warning')
      }
    }
  },

  beforeDestroy() {
    this.$bus.$off('contactEdit', this.listenEditContact)
    this.$bus.$off('refreshWODetailsBase', this.listenRefreshWO)
    this.$bus.$off('addedWOSite', this.listenAddWOSite)
    clearInterval(this.hasUnsavedChangesInterval)
    this.$store.dispatch('unsavedDataClear')
    // this.$bus.$off('checkUnsavedChanges', this.hasUnsavedChanges)
    this.$bus.$off('setStartFinishDates', this.listenSetStartFinishDates)
    this.$bus.$off('addProjectUpdate', this.listenaddProjectUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/paper/_variables.scss';

textarea {
  resize: vertical !important;
}

.file-uploader-container {
  margin-bottom: 20px;
}

.detail-item {
  .wo-number-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 10px;

    h4 {
      margin: 0;
      font-weight: bolder;
    }

    i {
      font-size: 12px;
    }
  }
}

input.invalid {
  background: $input-red;
}
</style>
